<template>
  <div class="main">
    <div class="swiper-mains">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide><img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/swiper_one.png" alt=""></swiper-slide>
        <swiper-slide><img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/swiper_two.png" alt=""></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- <div class="pz-box">
        <div class="pz">
          <div class="pz-img">
              <img src="https://pd-palmes.pdddd.com/TaoTuoApp/Plus/PosterTemplate/1648110558196.png" alt="">
              <div class="pz-title">甄选好钻</div>
          </div>
          <div class="pz-message">
            <div class="pz-main">------甄选货源 至臻服务-----</div>
            <div class="pz-main">
              <p>1.“甄选好钻”隶属杭州甄钻珠宝首饰有限公司旗下珠宝首饰全球采购批发平台。甄钻钻石从事珠宝首饰行业17余年，Promise 定义为应许，诚信经营。线下实体门店经营，发展全球品牌加盟业务。</p>
              <p>2.甄选好钻对接印度，香港，美国，比利时，以色列等世界顶级钻石供应商资源，整合全球钻石数据。</p>
              <p>3.杭州甄钻珠宝首饰有限公司已形成成熟的国际供应链，从钻石源头采购到工厂加工，珠宝展柜的陈列和销售都有行业沉淀的专业基础。</p>
            </div>
            <div class="pz-main"> ------珠宝首饰线上定制----- </div>
            <div class="pz-main"> 
              <p>甄选好钻APP是一款移动珠宝批发购物软件，具有搜索，浏览，商品购买，在线支付，订单查询等功能，为客户朋友提供简单，便捷的批发进货体验；</p>
              <p>1.成品展示：通过对首饰所展示的图片，结合客户朋友的需求爱好，在线上下订单或者选购库存的成品现货，找到适合自己的款式。</p>
              <p>2.裸石查询：集成全球钻石数据库，从钻石的形状，颜色，净度，切工，证书等全方位检索，线上直接报价或对接相关业务。</p>
            </div>
          </div>
        </div>
    </div> -->
    <div class="items-main">
      <div class="item">
        <div class="text-clounm">OPTIMIZATION DIAMOND</div>
        <div class="name">臻选钻石</div>
        <div class="toast-img" @click="navgo('/diamondSearch')">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/ringben.png" alt="">
          <div class="view-text">浏览全部钻石</div>
        </div>
      </div>
      <div class="item">
        <div class="text-clounm">ENGAGEMENT RING</div>
        <div class="name">求婚钻戒</div>
        <div class="toast-img" @click="navgo('/ringSearch?type=求婚钻戒')">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/diamond.png" alt="">
          <div class="view-text">浏览全部婚戒</div>
        </div>
      </div>
      <div class="item">
        <div class="text-clounm">WEDDING BANDS</div>
        <div class="name">结婚对戒</div>
        <div class="toast-img" @click="navgo('/ringSearch?type=结婚对戒')">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/ringdui.png" alt="">
          <div class="view-text">浏览全部对戒</div>
        </div>
      </div>
      <div class="item">
        <div class="text-clounm">DIAMOND JEWELRY</div>
        <div class="name">钻石饰品</div>
        <div class="toast-img" @click="navgo('/ringSearch')">
          <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/ringpei.png" alt="">
          <div class="view-text">浏览全部配饰</div>
        </div>
      </div>
    </div>

    <div class="items-type">
      <div class="titel">热门功能推荐</div>
      <div class="items">
        <div class="item" @click="navgo('/certSearch')">
          <div class="item-imgs">
            <img class="haverImg" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/cert.png" alt="">
          </div>
          <div class="type">证书查询</div>
          <div class="text">我们始终坚持，一钻双证，对品质严苛承诺</div>
          <div class="btn">
            <div>即刻查询</div>
            <span></span></div>
        </div>
        <div class="item" @click="navgo('/appointment')">
          <div class="item-imgs">
            <img class="haverImg" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/shop.png" alt="">
          </div>
          <div class="type">预约到店</div>
          <div class="text">为您提供珠宝专家一对一服务</div>
          <div class="btn">
            <div>即刻预约</div>
            <span></span></div>
        </div>
        <div class="item" @click="navgo('/diamondRules')">
          <div class="item-imgs">
            <img class="haverImg" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/c4.png" alt="">
          </div>
          <div class="type">钻石4C</div>
          <div class="text">钻石价格=克拉+颜色+净度+切工</div>
          <div class="btn">
            <div>即刻了解</div>
            <span></span></div>
        </div>
      </div>
    </div>

    <div class="items-type witer">
      <div class="titel">臻选婚戒推荐</div>
      <div class="items">
        <swiper :options="swiperOptions" ref="mySwipers">
          <swiper-slide v-for="(item, index) in ringList" :key="index">
            <div class="swiper-last-main">
              <img @click="getdel(item.standardNumber)" class="sildes-img" :src="item.image" alt="">
            </div>
          </swiper-slide>
          <div class="swiper-button-prev bub-left" slot="button-prev"></div>
          <div class="swiper-button-next bub-right" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
  import Shop from '@/api/shop'
  import Ring from '@/api/ring'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'

  export default {
    name: 'index',
    data () {
      return {
        // 轮播参数设置
        swiperOption: {
          loop: false,
          autoplay: {
            delay: 6000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          // 显示分页
          pagination: {
            el: '.swiper-pagination',
            clickable: true //允许分页点击跳转
          },
        },
        swiperOptions: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 38,
          freeMode: true,
          // 设置点击箭头
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        relationId: '',
        ringList: ''
      }
    },
    components: {
      swiper,
      swiperSlide
    },
    created () {
      // this.$emit('changrMeta', true)
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            document.title = shop.shopName
            this.$getif(shop.vipType)
            this.getList(newValue)
          }
        },
        immediate: true,
      }
    },
    mounted () {
    },
    methods: {
      getList (relationId) {
        var items = ''
        let params = {
          RelationId: relationId,
          StandardNumber: ''
        }

        Ring.RecommendList(params).then(res => {
          if (res.status == 200) {
            this.ringList = res.result.items
          }
        })
      },
      navgo (path) {
        this.$router.push({
          path: path
        })
      },
      getdel (id) {
        this.$router.push({
          path: `/ringDetails?relationId=${this.relationId}&standardNumber=${id}`
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;

    .swiper-mains {
      height: 660px;
      width: 100%;

      ::v-deep .swiper-container.swiper-container-initialized.swiper-container-horizontal {
        width: 100%;
        height: 100%;

        .swiper-slide.swiper-slide-duplicate {
          width: 100% !important;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      ::v-deep .swiper-pagination-bullet {
        background: #FFFFFF;
        opacity: 1;
      }

      ::v-deep .swiper-pagination-bullet-active {
        background: #EE8976;
      }
    }
    .pz-box{
      width: 100%;
      min-width: 1200px;
      padding: 90px 350px;
      background: #FFFFFF;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      user-select: none;
      border: 1px solid red;

      .pz{
        display: flex;
        .pz-img{
          padding-top: 80px;
        }
      }
      .pz-title{
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }
      .pz-message{
        margin-left: 20px;
        .pz-main{
          font-size: 15px;
        }
      }
    }
    .items-main {
      width: 100%;
      min-width: 1200px;
      padding: 90px 112px;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      .item {
        width: 300px;
        height: 525px;
        background: #FDD4CD;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 25px 0 41px;
        border-right: 1px solid #FB675C;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .text-clounm {
          color: #666666;
          font-size: 20px;
          letter-spacing: 8px;
          writing-mode: vertical-lr;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 26px;
        }

        .toast-img {
          position: absolute;
          top: 525px;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          animation: upDown .6s;
          animation-fill-mode: forwards;
          img {
            width: 100%;
            height: 100%;
          }

          .view-text {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 70px;
            background: rgba(255, 255, 255, .5);
          }

        }

        &:hover {
          .toast-img {
            animation: toptop .6s;
            animation-fill-mode: forwards;
          }
        }

        .name {
          color: #333333;
          font-size: 15px;
        }
      }
    }

    .items-type {
      width: 100%;
      min-width: 1200px;
      padding: 80px;
      background: #F5F5F5;

      &.witer {
        background: #ffffff;
      }

      .titel {
        text-align: center;
        width: 100%;
        margin-bottom: 70px;
        font-size: 32px;
        font-family: SimSun;
      }

      .items {
        width: 1200px;
        min-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        ::v-deep .swiper-container {
          width: 100%;
        }

        .swiper-last-main {
          width: 375px;
          height: 385px;
          overflow: hidden;
          cursor: pointer;

          .sildes-img {
            width: 100%;
            height: 100%;
          }

          .sildes-img:hover {
            animation: bigbig .3s;
            animation-fill-mode: forwards;
          }
        }

        .item {
          max-width: 382px;
          min-width: 380px;
          text-align: center;
          background: #fff;
          cursor: pointer;
          overflow: hidden;

          .item-imgs {
            width: 100%;
            height: 212px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }

            .haverImg:hover {
              animation: bigbig .6s;
              animation-fill-mode: forwards;
              /*transform: scale(1.3, 1.3);*/
            }
          }

          .type {
            margin-top: 36px;
            font-size: 28px;
            font-family: SimSun;
          }

          .text {
            font-size: 14px;
            margin-top: 16px;
            margin-bottom: 22px;
          }

          .btn {
            position: relative;
            margin-bottom: 32px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              display: inline-block;
              width: 6px;
              height: 6px;
              border-right: 1px solid #000000;
              border-top: 1px solid #000000;
              transform: rotate(45deg);
            }
          }


        }
      }
    }
  }

  .swiper-button-prev.swiper-button-disabled,.swiper-button-next.swiper-button-disabled{
    cursor: no-drop;
  }

  .swiper-button-prev,.swiper-container-rtl.swiper-button-next{
    background-image: url("https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/left.png");
    background-size: 34px 34px;
  }
  .swiper-button-next,.swiper-container-rtl.swiper-button-prev{
    background-image: url("https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/index/right.png");
    background-size: 34px 34px;
  }

  @keyframes toptop {
    from {
      top: 525px;
    }
    to {
      top: 0px;
    }
  }
  @keyframes upDown {
    from {
      top: 0px;
    }
    to {
      top: 525px;
    }
  }

  @keyframes bigbig {
    from {
      transform: scale(1.0, 1.0);
    }
    to {
      transform: scale(1.3, 1.3);
    }
  }
</style>
